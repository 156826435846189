import React, { useState } from "react";
import "../style/loginComp.scss";
import SplashModal from "./SplashModal";
import { useNavigate } from "react-router-dom";

const LoginComponent = () => {
  const navigate = useNavigate();
  const [getCourse, setCourse] = useState("");
  const [getRollNo, setRollNo] = useState("");
  const [getDOB, setDOB] = useState("");
  const [checkCaptcha, setCheckCaptcha] = useState("");
  const [captcha, setCaptcha] = useState(
    `${Math.floor(Math.random() * 9) + 1}${Math.floor(Math.random() * 9) + 1}${
      Math.floor(Math.random() * 9) + 1
    }${Math.floor(Math.random() * 9) + 1}`
  );
  const [loading, setLoading] = useState(false);

  return (
    <div className="loginContainer">
      <div className="loginContainerOne">
        <div className="loginDesignContainer">
          <img
            src="/loginImage.webp"
            alt="login"
            style={{
              width: "100%",
              display: "block",
            }}
          />
        </div>
      </div>
      <div className="loginContainerTwo">
        <div className="loginSection">
          <div
            style={{
              width: "100%",
              height: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "20px",
              fontWeight: "bold",
              background: "linear-gradient(45deg, red, blue)",
              color: "whitesmoke",
            }}
          >
            Student Console
          </div>
          {/*  */}
          <div className="eachFieldContainer">
            <label htmlFor="course">Course:</label>
            <select
              id="course"
              style={{ width: "100%", height: "100%", cursor: "pointer" }}
              value={getCourse}
              onChange={(e) => {
                e.preventDefault();
                setCourse(e.currentTarget.value);
              }}
            >
              <option value="">Select Course</option>
              <option value="bed">{"Bachelor of Education (B.Ed)"}</option>
              <option value="deled">
                {"Diploma In Elementary Education (DELEd)"}
              </option>
              <option value="comp">
                {"Computer Course (C.C.A, A.D.C.A, D.C.A, D.F.A, etc)"}
              </option>
            </select>
          </div>
          {/*  */}
          <div className="eachFieldContainer">
            <label htmlFor="roll_no">Enrollment No:</label>
            <input
              id="roll_no"
              type="text"
              placeholder="Enter Your Enrollment No"
              style={{ width: "100%", height: "100%" }}
              value={getRollNo}
              onChange={(e) => {
                e.preventDefault();
                setRollNo(e.currentTarget.value.toUpperCase());
              }}
            />
          </div>
          <div className="eachFieldContainerText" style={{ fontSize: "14px" }}>
            {getCourse === ""
              ? "If You don't have your Enrollment No please contact +91 9832368234"
              : getCourse === "COMPUTER"
              ? "Eg: 22A01-XXXX"
              : "Eg: 223XXXX"}
          </div>
          {/*  */}
          <div className="eachFieldContainer">
            <label htmlFor="dob">Date Of Birth:</label>
            <input
              id="dob"
              type="text"
              placeholder="Enter Your Date Of Birth"
              style={{ width: "100%", height: "100%" }}
              value={getDOB}
              onChange={(e) => {
                e.preventDefault();
                setDOB(e.currentTarget.value.toUpperCase());
              }}
            />
          </div>
          <div className="eachFieldContainerText">
            {"Eg: DD-MM-YYYY , Like: 08-03-1998"}
          </div>
          <div className="captchaFieldContainer">
            <div
              style={{
                width: "50%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <label
                htmlFor="captcha"
                style={{
                  fontSize: "25px",
                  marginRight: "8px",
                  letterSpacing: "8px",
                  background:
                    "repeating-linear-gradient(50deg, #000000, #1b9bc600 1px)",
                }}
              >
                {captcha}
              </label>
              <a
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  setCaptcha(
                    `${Math.floor(Math.random() * 9) + 1}${
                      Math.floor(Math.random() * 9) + 1
                    }${Math.floor(Math.random() * 9) + 1}${
                      Math.floor(Math.random() * 9) + 1
                    }`
                  );
                  setCheckCaptcha("");
                }}
              >
                <i className="fa-solid fa-rotate-right"></i>
              </a>
            </div>
            <div
              style={{
                width: "50%",
                height: "100%",
                backgroundColor: "green",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                id="captcha"
                type="text"
                placeholder="Enter Captcha"
                style={{ width: "100%", height: "100%" }}
                maxLength={4}
                value={checkCaptcha}
                onChange={(e) => {
                  e.preventDefault();
                  setCheckCaptcha(e.target.value);
                }}
              />
            </div>
          </div>
          {/*  */}
          {/*  */}
          <div
            style={{
              width: "100%",
              height: "auto",
              margin: "5px 0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <input
              type="reset"
              value="Reset"
              id="resetButtonDesign"
              onClick={(e) => {
                e.preventDefault();
                setCourse("");
                setDOB("");
                setRollNo("");
                setCheckCaptcha("");
                setCaptcha(
                  `${Math.floor(Math.random() * 9) + 1}${
                    Math.floor(Math.random() * 9) + 1
                  }${Math.floor(Math.random() * 9) + 1}${
                    Math.floor(Math.random() * 9) + 1
                  }`
                );
              }}
            />
            <input
              type="submit"
              value="Search"
              id="submitButtonDesign"
              onClick={(e) => {
                e.preventDefault();
                setLoading(true);
                if (
                  getCourse !== "" &&
                  getRollNo !== "" &&
                  getDOB !== "" &&
                  checkCaptcha !== ""
                ) {
                  if (checkCaptcha === captcha) {
                    if (getCourse.toLowerCase() === "comp") {
                      navigate(`/auth/comp/${getRollNo}`);
                    } else if (getCourse.toLowerCase() === "bed") {
                      navigate(`/auth/bed/${getRollNo}`);
                    } else if (getCourse.toLowerCase() === "deled") {
                      navigate(`/auth/deled/${getRollNo}`);
                    }
                  } else {
                    setTimeout(() => {
                      setLoading(false);
                      alert("Captcha not matched!");
                    }, 1500);
                  }
                } else {
                  setTimeout(() => {
                    setLoading(false);
                    alert("Please enter the credentials carefully!");
                  }, 1500);
                }
              }}
            />
          </div>
          {/*  */}
          {/*  */}
          <SplashModal display={loading} />
          {/*  */}
          {/*  */}
          <div
            style={{
              width: "100%",
              height: "auto",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "12px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              paddingRight: "5px",
            }}
          >{`Version: v1.2.0`}</div>
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;
