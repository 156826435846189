import React from "react";
import "../../style/auth/userDetails.scss";

const UserDetails = ({ enroll, course, userData }) => {
  const EachDetails = () => {
    return (
      <div className="eachDetailsContainer">
        <div className="eachDetailsContainerOne">
          <div className="eachDetailsContainerOneEachDiv">
            Father's Name:
            <span className="eachDetailsContainerOneEachDivText" id="md-bg-yes">
              {userData[0]?.FATHER_NAME}
            </span>
          </div>
          {/*  */}
          <div className="eachDetailsContainerOneEachDiv">
            Mother's Name:
            <span className="eachDetailsContainerOneEachDivText" id="md-bg-yes">
              {userData[0]?.MOTHER_NAME}
            </span>
          </div>
          {/*  */}
          <div className="eachDetailsContainerOneEachDiv">
            Date Of Birth:
            <span className="eachDetailsContainerOneEachDivText">
              {userData[0]?.DATE_OF_BIRTH}
            </span>
          </div>
          {/*  */}
          <div className="eachDetailsContainerOneEachDiv">
            Category/Cast:
            <span className="eachDetailsContainerOneEachDivText">
              {userData[0]?.CAST}
            </span>
          </div>
          {/*  */}
          <div className="eachDetailsContainerOneEachDiv">
            Contact No:
            <span className="eachDetailsContainerOneEachDivText">
              {`+91 ${userData[0]?.CONTACT_NO}${
                userData[0]?.ALTERNATE_NO === undefined
                  ? ""
                  : `, ${userData[0]?.ALTERNATE_NO}`
              }`}
            </span>
          </div>
          {/*  */}
          <div className="eachDetailsContainerOneEachDiv">
            Aadhar No:
            <span className="eachDetailsContainerOneEachDivText">
              {userData[0]?.ADHAR_NO}
            </span>
          </div>
          {/*  */}
          <div className="eachDetailsContainerOneEachDiv">
            Date Of Admission:
            <span className="eachDetailsContainerOneEachDivText">
              {userData[0]?.DATE_OF_ADMISSION}
            </span>
          </div>
          {/*  */}
          <div className="eachDetailsContainerOneEachDiv">
            Address:
            <span className="eachDetailsContainerOneEachDivText">
              {userData[0]?.ADDRESS}
            </span>
          </div>
        </div>
        {/*  */}
        {/*  */}
        {/*  */}
        {/*  */}
        <div className="eachDetailsContainerTwo">
          <div
            style={{
              width: "200px",
              height: "150px",
              // backgroundColor: "red",
              position: "absolute",
              marginTop: "-30px",
              marginLeft: "-82px",
            }}
          >
            <img
              src={"/stamp.svg"}
              width={"150px"}
              height={"100px"}
              alt="kheci_stamp"
            />
          </div>
          <img
            src={
              course === "bed" || course === "deled"
                ? "https://kheci.org/bed_students/download.png"
                : `https://kheci.org/computer_student_images/${userData[0]?.ROLL_NO}.jpg`
            }
            alt="kheci_student_photo"
            width={"150px"}
            height={"180px"}
            style={{
              border: "1px solid black",
            }}
          />
        </div>
      </div>
    );
  };
  return (
    <div className="userDetialsContainer">
      {/*  */}
      <div className="headerFirst">
        <div className="headerFisrtBoxOne">{enroll}</div>
        <div className="headerFirstBoxTwo">
          <h4>{userData[0]?.COURSE}</h4>
          <span>{userData[0]?.COURSE_NAME}</span>
        </div>
      </div>
      {/*  */}
      <div className="headerSecond">
        <span> {userData[0]?.NAME}</span>
      </div>
      {/*  */}
      {/*  */}
      <EachDetails />
      {/*  */}
      {/*  */}
    </div>
  );
};

export default UserDetails;
